html,
body {
  margin: 0;
  min-height: 100vh;
  background: linear-gradient(
    120deg,
    rgba(78, 107, 189, 1) 0%,
    rgba(120, 104, 191, 1) 100%
  );

  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

body {
  display: flex;
  flex-direction: column;
}

h1 {
  color: whitesmoke;
}

.Greeting {
  font-size: calc(20px + 10vmin);
  padding-top: 6vh;
  margin-top: 8vh;
  padding-bottom: 0;
  margin-bottom: 0;
}

.Main,
.link {
  -webkit-animation: fadein 0.3s linear forwards;
  animation: fadein 0.3s linear forwards;
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.link {
  flex-direction: row;
  margin-top: 4vh;
}

.MyLinks {
  font-size: calc(10px + 10vmin);
  padding-top: 6vh;
  margin-bottom: 0;
  margin-top: 8vh;
}

.Desc {
  margin-top: 4vh;
}

.resume {
  width: 21vh;
  height: 5vh;
  font-size: calc(1px + 2vmin);
}

.about {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 10%;
  padding-top: 0vh;
  overflow-wrap: break-word;
  width: 30%;
}

.Projects {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: rgb(25, 25, 43);
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 15%;
  overflow-wrap: break-word;
  width: 30%;
  /* align-items: center; */
}

.Main {
  display: flex;
  flex-direction: row;
}
.content {
  background-color: #def2ff;
  margin-left: 5vh;
  margin-right: 5vh;
  border-radius: 50px;
  padding: 2vh;
}

.hr {
  flex: 1;
  /* height: 45vh; */
  padding-top: 5vh;
}
.CurrentProjectsH {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(25, 25, 43);
  font-family: Arial, Helvetica, sans-serif;
}
