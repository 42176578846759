.CurrProj {
  padding-top: 3vh;
  padding-left: 2vh;
  padding-right: 2vh;
  padding-bottom: 2vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.card {
  background: #efaac4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}

.desc {
  padding-left: 2vh;
  font-size: calc(2px + 2vmin);
  color: rgb(10, 13, 24);
  font-family: Arial, Helvetica, sans-serif;
  height: 20vh;
  width: 40vh;
}
